@import "ag-grid-community/src/styles/ag-grid.scss";
@import "ag-grid-community/src/styles/ag-theme-alpine/sass/ag-theme-alpine-mixin.scss";
@import "ag-grid-community/src/styles/ag-theme-alpine-dark/sass/ag-theme-alpine-dark-mixin.scss";

.ag-theme-alpine {
  @include ag-theme-alpine((
    // odd-row-background-color: #BFC8DC
    // selected-row-background-color: ag-derived(background-color, $mix: foreground-color 25%),
    selected-row-background-color: #bbdefb,
  ));

  font-family: -apple-system, BlinkMacSystemFont, 'Noto Sans JP', 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif !important;
}

.ag-theme-alpine-dark {
  @include ag-theme-alpine-dark((
    // odd-row-background-color: #BFC8DC
    // selected-row-background-color: ag-derived(background-color, $mix: foreground-color 25%),
    // selected-row-background-color: #37474f,
    selected-row-background-color: #37474f,
  ));

  font-family: -apple-system, BlinkMacSystemFont, 'Noto Sans JP', 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif !important;
}

.ag-header-cell-label {
  justify-content: center !important;
}

// .leaflet-container {
//   // width: 100wh; 
//   // height: 10vh;
//   width: 600px; 
//   height: 300px;
// }
